import React, { useEffect, useState } from 'react';
import emailjs from 'emailjs-com';
import HeroSection from '../components/HeroSection/HeroSection';
import SectionTitle from '../components/SectionTitle/SectionTitle';
import { CustomTextInput, CustomTextAreaInput } from '../components/Input/Input';
import Button from '../components/Button/Button';
import Quote from '../components/GetInTouch/Quote';
import './Contact.scss';
import UpdateBodyBackground from '../utils/UpdateBackground';
import { Helmet } from 'react-helmet';
import { toast } from 'sonner'

const Contacts = () => {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    message: '',
  });
  const [feedbackMessage, setFeedbackMessage] = useState(''); // State to store feedback messages
  const [messageType, setMessageType] = useState(''); // State to store the type of message (success or error)
  const [emailError, setEmailError] = useState('');

  UpdateBodyBackground();
  useEffect(() => {
    const timer = setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500); // Delay might need adjustment
    return () => clearTimeout(timer);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (name === 'email') {
      setEmailError('');
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const sendEmail = (e) => {
    e.preventDefault();

    if (!validateEmail(formData.email)) {
      setEmailError('Please enter a valid email address.');
      return;
    }
    // Create the template parameters
    const templateParams = {
      from_name: `${formData.first_name} ${formData.last_name}`,
      message: formData.message,
      email: formData.email,
      to_name: 'Phinexa team' // This should be the recipient's name
    };

    emailjs.send('service_nt983aq', 'template_3u9mz2f', templateParams, '61GUFem6V-CCgsncN')
      .then(
        (result) => {
          console.log('SUCCESS!', result.text);
          //setFeedbackMessage('Your message has been sent successfully!');
          toast.success('Your message has been sent successfully!');
          setMessageType('success');
          setFormData({
            first_name: '',
            last_name: '',
            email: '',
            message: '',
          });
        },
        (error) => {
          console.log('FAILED...', error.text);
          //setFeedbackMessage('Failed to send message. Please try again later.');
          toast.error('Failed to send message. Please try again later.');
          setMessageType('error');
        }
      );
  };

  return (
    <div className='page projects'>
      <Helmet>
        <title>Contact Us - Global Learning Lab</title>
        <meta
          name="description"
          content="Get in touch with Global Learning Lab for inquiries, partnerships, or to learn more about our programs and initiatives. Contact us today to start making a positive impact."
        />
        <link rel='canonical' href='https://globallearninglab.org/contact' />
      </Helmet>

      {/* ------------- Hero Section -------------*/}

      {/* <HeroSection
        title='Get in Touch'
        description='Explore our impact featured projects, each designed to connect, educate, and inspire. 
        Discover initiatives that empower global citizens, romote cultural exchange, 
        and drive cross-cultural understanding.'
        /> */}
      <Quote title='&quot;I can do things you cannot, you can do things I cannot; together we can do great things.&quot;'
        description='&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  - Mother Theresa' />

      {/* ------------- Projects Section -------------*/}

      <SectionTitle
        title="Let's Do Great Things Together"
        description=''
        type='quote'
      />

      <div className="container contacts">
        <div className="contact">
          <div className="left">
            <img src="/images/contact-input.png" alt="" />
          </div>
          <form className="right" onSubmit={sendEmail}>
            <div className="row">
              <CustomTextInput
                label='First Name'
                name='first_name'
                value={formData.first_name}
                onChange={handleChange}
              />
              <CustomTextInput
                label='Last Name'
                name='last_name'
                value={formData.last_name}
                onChange={handleChange}
              />
            </div>
            <div className={`email-input-wrapper ${emailError ? 'has-error' : ''}`}>
              <CustomTextInput
                label="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                style={{
                  borderColor: emailError ? 'red' : undefined,
                }}
              />
              <div>
                {emailError && (
                  <p className={`error-text ${emailError ? 'error-visible' : ''}`}>
                    {emailError}
                  </p>
                )}
              </div>
            </div>
            <CustomTextAreaInput
              label='Message'
              name='message'
              value={formData.message}
              onChange={handleChange}
            />
            <Button label='Submit' type='primary' />
            {feedbackMessage && (
              <div className={`message ${messageType === 'error' ? 'error' : 'success'}`}>
                {feedbackMessage}
              </div>
            )}
          </form>
        </div>
      </div>

    </div>
  );
}

export default Contacts;

//info@globallearninglab.org